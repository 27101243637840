import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { UqArticleQuote, UqCustomerStory, UqCustomerProfile, UqRequestDemoBanner, UqContainerV2, UqTypography, UqLink, UqPage, UqAppBarV2, UqLayoutV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import Logo from '@uq-assets/logos/lovoo.svg';

import * as styles from './lovoo.module.scss';

export default function LovooPage() {
  return (
    <UqPage
      config={{
        seo: {
          description: 'Read LOVOO\'s story to see how they\'ve leveraged unitQ to boost their ratings by 28%.',
          src: '/images/v2/customers/lovoo/lovoo-hero.png',
          title: 'LOVOO Customer Story',
        },
      }}
    >
      <UqLayoutV2>
        <UqAppBarV2>
          <UqNavbarV2 />
        </UqAppBarV2>

        <UqCustomerStory>
          <UqCustomerStory.Header
            backgroundSrc="/images/v2/customers/lovoo/lovoo-hero.png"
            classes={{ background: styles.hero }}
          >
            <UqTypography as="h1">
              {'LOVOO Loves unitQ: <br /> Bringing a Higher Quality Experience to the Dating Scene'}
            </UqTypography>
          </UqCustomerStory.Header>

          <UqCustomerStory.Content>
            <UqCustomerProfile logo={<Logo />}>
              <UqCustomerProfile.Section title="Product">Online Dating</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Headquarters">Germany</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Employees">140</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Quality Challenge">Manual process, Zendesk, in-app</UqCustomerProfile.Section>
              <UqCustomerProfile.Section title="Results">
                <ul>
                  <li>
                    <strong>42% improvement</strong> in the unitQ Score
                  </li>
                  <li>
                    <strong>28% improvement</strong> in App Store and Google Play ratings
                  </li>
                  <li>
                    <strong>Increased session length</strong> and average sessions per day
                  </li>
                  <li>
                    <strong>40% reduction</strong> in support ticket volumes
                  </li>
                </ul>
              </UqCustomerProfile.Section>
            </UqCustomerProfile>

            <UqCustomerStory.Article>

              <p>
                <UqLink
                  className="article-link"
                  to="https://lovoo.com"
                  external
                  hideArrow
                >
                  LOVOO
                </UqLink>{' '}
                <span>
                  helps match millions of people with that someone special,
                  be it for a quick icebreaker or a lifetime commitment.
                  Their service differentiates by going well beyond the
                  simple swiping of photos to provide an authentic, high
                  quality experience. But, since every connection is sparked
                  on the LOVOO smartphone app, nothing derails a budding
                  love connection faster than a frustrating glitch.
                </span>
              </p>

              <UqTypography as="h4">
                Frustrated with !-Friction-!
              </UqTypography>

              <p>
                App quality issues usually prompted annoyed users to stop
                their dating ambitions and let someone know. Those quality
                issues ranged from trouble sending chats and disappearing
                matches, to revenue-impacting issues, like the inability
                to upgrade to a premium account. Their primary outlet was
                either a disappointing app store review, an angry social
                post, or a message to LOVOO support. But finding those
                omnichannel complaints, getting them sorted, and making
                sure they were quickly routed to LOVOO product and
                engineering teams was a friction-filled process. And, with
                millions of global users interacting in 15 different
                languages, even a minor quality issue could balloon into a
                disruptive product crisis.
              </p>

              <UqTypography as="h4">
                Struggling with a !-Manual Process-!
              </UqTypography>

              <p>
                At the heart of their pain was an entirely manual process
                to capture, parse, and prioritize incoming quality issues.
                Making matters worse, their visibility was limited to only
                those issues arriving via their Zendesk support system and
                in-app chatbot, so they missed out-of-band issues and
                frequently underestimated the impact. But, even through
                their monitored channels, incoming tickets reached close
                to 40,000 per month, which meant their Quality Assurance
                (QA) team had to manage over 1,000 tickets per day.
              </p>
              <p>
                But even when QA did spot a potential issue, they had
                trouble making sense of it; uncovering its scope across
                operating systems, versions, and devices; quantifying its
                impact and prioritizing it against other, in-process
                improvements; and then getting it into the Engineering
                queue.
              </p>
              <p>
                This manual QA process was not only slow and expensive, it
                lacked many of the background details required for a quick
                and effective fix. Furthermore, LOVOO missed
                quality-related comments made in app store reviews and on
                social media. That is, until they connected with unitQ.
              </p>

              <UqTypography as="h4">
                Getting Quality Insights !-with unitQ-!
              </UqTypography>

              <p>
                LOVOO chose unitQ Monitor for constant, cross-channel
                monitoring and analysis that identified quality issues and
                alerted the right teams, all while reducing the cost and
                effort, and freeing up QA to work with Engineering on
                fixes. unitQ integrated with Zendesk in just 10 minutes,
                and unitQ APIs completes the quality picture with data
                from LOVOO’s in-app chatbot and from Apple App Store and
                Google Play reviews. unitQ also added device, language,
                and platform metadata to pinpoint the source of quality
                issues. These complete and granular details helped
                accelerate prioritization, and also helped Engineering
                find and fix issues faster.
              </p>

              <UqArticleQuote
                author={{
                  name: 'Anja Richter',
                  role: 'Head of QA, LOVOO',
                }}
              >
                We love unitQ Monitor! It used to take us so much time
                to identify and quantify issues,” said the head of QA at
                LOVOO. “unitQ Monitor has really improved the workflow
                between our QA and Product teams, because we now have
                all the data we need to make decisions in real time.
                Plus, it’s great that we can now search everything from
                one place!
              </UqArticleQuote>

              <UqTypography as="h4">
                Better Quality for a !-Better Experience-!
              </UqTypography>

              <p>
                <strong>
                  Users noticed the improvements, raising LOVOO’s unitQ
                  Score - The Quality Metric by 42%, from 65 up to 92/100.
                  And, in just a few months, their App Store ratings
                  increased from 3.7 to 4.6 and Play Store ratings rose
                  from 3.2 to 4.1.
                </strong>
              </p>

              <p>
                This combination of channel data, quantified insights, and
                noise reduction makes unitQ the single source of truth for
                quality at LOVOO.
              </p>

              <br />
              <p>
                <StaticImage
                  src="/images/v2/customers/lovoo/lovoo-growth-chart.jpg"
                  alt="LOVOO growth chart"
                />
              </p>
              <br />

              <p>
                In a space where quality definitely matters, LOVOO now has
                a cost effective process for spotting issues faster. unitQ
                quantifies potential issues in real-time to offer
                indisputable reporting to hone in on issues and prioritize
                fixes with Engineering. And unitQ uses advanced machine
                learning to filter out the noise of other user feedback
                unrelated to quality issues, allowing LOVOO to quickly
                route feedback to the support team, saving everyone even
                more time.
              </p>
            </UqCustomerStory.Article>
          </UqCustomerStory.Content>
        </UqCustomerStory>

        <UqContainerV2>
          <UqRequestDemoBanner />
        </UqContainerV2>

        <UqFooterV2 />
      </UqLayoutV2>
    </UqPage>
  );
}
